import {VersionReadyEvent} from '@angular/service-worker';

export interface SemVer {
  major: number;
  minor: number;
  patch: number | string;
}

export interface UpdateAppData extends VersionReadyEvent {
  currentVersion: {
    hash: string;
    appData?: {
      version?: string;
    };
  };
  latestVersion: {
    hash: string;
    appData?: {
      version?: string;
    };
  };
}

export function formatVersion(currentVersion: string): SemVer {
  const version = currentVersion.split('.').map((value, index) => {
    const v = parseInt(value, 10);
    if (index === 0 && !Number.isFinite(v)) {
      return 7; // fix for versions not reflecting the release tags e.g. release-7.13
    } else {
      return v;
    }
  }) as [number, number, number];

  return {
    major: version[0],
    minor: version[1],
    patch: version[2]
  };
}

let timeout;

export function checkDisplayToast(data: UpdateAppData, currentVersion: string, displayToast: () => void): void {
  if (timeout) {
    clearTimeout(timeout);
  }
  if (data.latestVersion.appData && currentVersion) {
    const current = formatVersion(currentVersion);
    const available = formatVersion(data.latestVersion.appData.version);
    if (isPatchVersion(current, available)) {
      // display toast after 24 hours if user does not logout, closes tab, refreshes page manually
      timeout = setTimeout(displayToast, 24 * 60 * 60 * 1000);
    } else {
      // major version update -> display toast immediately
      displayToast();
    }
  } else {
    displayToast();
  }
}

export function isMajorOrMinorVersion(current: SemVer, available: SemVer): boolean {
  return !isPatchVersion(current, available);
}

function isPatchVersion(current: SemVer, available: SemVer): boolean {
  return current.major === available.major && current.minor === available.minor;
}
